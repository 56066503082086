<template>
  <div>
    <div class="mode-bg"></div>
    <PreorderMode :has_variant="false" @select="onSelect" />
    <Navbar
      :cafe="{}"
      :target="{ preorder: true }"
      :show_status="false"
      show_how
      :choosing="true"
      v-if="isMobile"
    />

    <OrderButtons showArrow @back="toMarketplace" />
  </div>
</template>

<script>
import PreorderMode from "@/components/PreorderMode.vue";
import Navbar from "@/components/Navbar.vue";

import OrderButtons from "@/components/OrderButtons.vue";

export default {
  data() {},
  methods: {
    onSelect(is_delivery) {
      const mode = is_delivery ? "delivery" : "preorder";
      window.location.href = `https://delivery.qrwaiter.com.ua/cafes.html?mode=${mode}`;
    },
    toMarketplace() {
      window.location.href = "https://delivery.qrwaiter.com.ua";
    },
  },

  components: {
    PreorderMode,
    Navbar,
    OrderButtons,
  },
};
</script>

<style scoped>
.mode-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: #1d1d1b;
}

.btn-back {
  background: #1d1d1b;
  border-radius: 5vh;
  position: absolute;
  padding: 2vh;
  left: 2.5vh;
  bottom: -25vh;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
}
</style>
