<template>
  <div>
    <div class="btns-container">
      <div class="bg-wrapper" :style="wrapperStyle">
      <div
        :class="activeTab == 1 ? 'active' : ''"
        @click="switchToMenu()"
        class="nav-btn menu"
      >
        {{ $t("order_buttons.menu") }}
      </div>
      <div
        :class="activeTab == 2 ? 'active' : ''"
        @click="switchToCart()"
        class="nav-btn cart"
      >
        {{ $t("order_buttons.cart") }}
      </div>
      <span 
        :class="{
          amount: true,
          'call-amount': shouldAnimateAmount
        }" v-if="amount > 0">{{
        amount
      }}</span>
    </div>
    </div>
  </div>
   
</template>

<script>
import OrderSaver from "@/services/order/saver.js";

export default {
  props: {
    amount: Number,
    showArrow: Boolean,
    mode: {
      type: String,
      default: "menu",
    },
    cartLocked: Boolean,
    target: Object,
    order: Object
  },
  data() {
    return {
      activeTab: 1,
      shouldAnimateAmount: false
    };
  },
  watch: {
    amount(n,o) {
      if (n == 1 && !o) {
        this.shouldAnimateAmount = true;
      }
    }
  },
  methods: {
    switchTab(id) {
      this.activeTab == 1 ? this.switchToMenu() : this.switchToCart();
      this.activeTab = id;
    },
    fuckGoBack() {
      this.$emit("back");
    },
    switchToCart() {
      this.activeTab = 2;
      this.$emit("switchView", "cart");
    },
    switchToMenu() {
      this.activeTab = 1;
      this.$emit("switchView", "menu");
    },
    emitPay() {
      this.$emit("pay");
    },
    emitSend() {
      this.$emit("send");
    },
    checkMode() {
      if (this.mode == "cart") {
        this.activeTab = 2;
      } else {
        this.activeTab = 1;
      }
    },
  },
  computed: {
    getCorrectNoun() {
      return this.$tc("order_buttons.dishes", this.amount);
    },
    getTotalPrice() {
      return this.$store.getters["cart/total"];
    },
    getOrderCorrectNoun() {
      if (!this.order || !this.order.list) return "";
      return this.$tc("order_buttons.dishes", this.order.list.length);
    },
    getOrderTotalPrice() {
      return this.order.total;
    },
    backArrowStyle() {
      return {
        visibility: this.showArrow ? "visible" : "hidden",
      };
    },
    shouldShowCartInMenu() {
      if (this.cartLocked) return true;
      if (this.target.lite) return false;

      return this.amount > 0;
    },
    shouldShowMenuBtns() {
      // if (this.mode !== "menu") return false;
      // if (this.cartLocked) return true;

      if (this.amount == 0) return false;

      if (this.cartLocked) return true;

      return true;
    },
    currentMode() {
      if (this.$route.name == "preorder") return "preorder";
      if (this.$route.name == "delivery") return "delivery";
      if (this.$route.name == "hotel_room") return "hotels";

      return "inside";
    },
    canPay() {
      if (this.currentMode != "inside") return false;
      return !!this.order && ["serving", "unpaid"].includes(this.order.status);
    },
    canSend() {
      if (this.currentMode != "inside") return false;
      return this.$store.state.cart.items.length;
    },
    currency() {
      return this.$store.state.currency.current;
    },
    wrapperStyle() {
      const side = this.activeTab == 1 ? 'left': 'right';
      return {
        [`border-top-${side}-radius`]: '1vh',
        [`border-bottom-${side}-radius`]: '1vh',
      }
    }
  },
  mounted() {
    this.checkMode();
    // console.log(this.mode +  ' - mode');
  },
};
</script>

<style scoped>
@media screen and (min-width: 900px) {
  .btns-container {
    display: none !important;
  }
}

.bg-wrapper {
  background: #E5E5E5;
  width: 100%;
  border-radius: 0.6vh;
  display: flex;
}

.nav-btn {
  z-index: 1;
  width: 50%;
  height: 3.8vh;
  background: #e5e5e5;
  border-radius: 0.6vh;
  position: relative;
  font-size: 2vh;
  text-align: center;
  color: #908f8d;
  transition: 0.1s;
  padding-top: 0.4vh;
}

.nav-btn.active {
  z-index: 1;
  background: #1d1d1b;
  color: #ededed;
}

.btns-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 5;
  width: 100%;
  background: rgba(29, 29, 27, 0.01);
  backdrop-filter: blur(20px);
  padding: 2.5vh;
  margin: 0;
  bottom: 0;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.btn-back img {
  height: 2.3vh;
  width: 2.3vh;
  margin: 2.5vh;
}

.amount {
  background: #e2223b;
  color: #ededed;
  margin: 0;
  z-index: 1;
  position: absolute;
  font-size: 1.7vh;
  right: 1.5vh;
  bottom: 4.8vh;
  border-radius: 5vh;
  text-align: center;
  line-height: 1.8;
  padding: 0 1.1vh;
  font-weight: 600;
}

.call-amount {
    animation: amount 0.5s;
}

@keyframes amount {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes basket {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>